import baseTheme from "@nextblick/gatsby-theme-hdb/src/gatsby-plugin-theme-ui";
import { merge } from "theme-ui";

// merge will deeply merge custom values with the theme's defaults
export default merge(baseTheme, {
  colors: {
    text: "#000",
    primary: "#39b54a",
    primaryDark: "#166321",
    background: "#fff",
    backgroundLight: "#f5f3f1",
    headerBg: "#fff",
  },
  fonts: {
    body: "Study, sans-serif",
    heading: "Study, sans-serif",
    headingSecondary: "Canela Bark, sans-serif",
  },
  sizes: {
    headerHeight: 100,
    headerLogoHeight: 60,
    subMenuHeight: 60,
  },
  text: {
    boxed: {
      backgroundColor: "transparent",
      color: "text",
      textTransform: "uppercase",
    },
  },
});
